import React from "react";
import Helmet from "react-helmet";
const MetaDecorator = ({ title, description, image }) => {
	return (
		<Helmet>
			<title>KUPA Station | {title}</title>
			<meta name="description" content={description} />
			<meta property="og:image" key="og:image" content={image} />
		</Helmet>
	);
};

export default MetaDecorator;
