function ProductsCard({
	title,
	imgSrc,
	width,
	divHeight,
	height,
	clickable,
	id,
	text,
}) {
	return (
		<div
			className="products-card"
			style={{
				width: width ?? "180px",
				height: height + divHeight ?? "150px",
				objectFit: "contain",
			}}
		>
			<img
				src={imgSrc}
				className="img-center"
				style={{
					width: width ?? "180px",
					height: height ?? "130px",
					objectFit: "contain",
				}}
				alt={"kupa product"}
			/>
			<h6 className="text-center px-2 ">{title}</h6>

			{/* <p className="textKupa m-4 text-justify">{text}</p> */}
		</div>
	);
}

export default ProductsCard;
