import i18n from "i18nextConf";
import { Trans, useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { onMobile } from "views/global/App";
import portailEn from "../../assets/img/portail/portailEn.jpg";
import portailFr from "../../assets/img/portail/portailFr.jpg";

function OutilGestion() {
	const { t } = useTranslation();

	return (
		<div>
			{!onMobile() ? (
				<div className=" gridColumns">
					<div className=" d-flex justify-content-center">
						<img
							className="bigImage "
							src={i18n.language === "en" ? portailEn : portailFr}
							alt={t("navbar.managementtool")}
						/>
					</div>
					<div className="m-3 paragraph">
						<Container className="px-5  ">
							<h1 className="text-center">
								{t("navbar.managementtool")}
							</h1>

							<div className="textKupa text-justify mt-3">
								<Trans i18nKey="services.managementtool.text"></Trans>
							</div>

							<div className="mt-4 d-flex justify-content-center"></div>
						</Container>
					</div>
				</div>
			) : (
				<div className="mt-5">
					<Container>
						<div className="text-center px-4">
							<h1 className="text-center">
								{t("navbar.managementtool")}
							</h1>
							<div className="textKupa text-justify mt-2">
								<Trans i18nKey="services.managementtool.text"></Trans>
							</div>{" "}
						</div>
						<div className="mt-4 d-flex justify-content-center"></div>
					</Container>
					<img
						className="bigImage "
						src={i18n.language === "en" ? portailEn : portailFr}
						alt={t("navbar.managementtool")}
					/>
				</div>
			)}
		</div>
	);
}

export default OutilGestion;
