import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import keys from "../../assets/json/keys.json";

function Messenger() {
	const { t, i18n } = useTranslation();

	const language = `${i18n.language}_CA`;

	useEffect(() => {
		window.fbAsyncInit = () => {
			window.FB.init({
				xfbml: true,
				version: "v8.0",
			});
		};

		(function (d, s, id) {
			let js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src =
				"https://connect.facebook.net/" +
				language +
				"/sdk/xfbml.customerchat.js";
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div
				className="fb-customerchat"
				attribution="setup_tool"
				page_id={keys.FACEBOOK_ID}
				theme_color="#18b0d5"
				logged_in_greeting={t("messenger.message")}
				logged_out_greeting={t("messenger.message")}
			/>
		</>
	);
}

export default Messenger;
