import { useState } from "react";

export const useSwipe = (input) => {
	const [touchStart, setTouchStart] = useState(0);
	const [touchEnd, setTouchEnd] = useState(0);

	const minSwipeDistance = 50;

	const onTouchStart = (e) => {
		setTouchEnd(0);
		setTouchStart(e.targetTouches[0].clientX);
	};

	const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		const isRightSwipe = distance < -minSwipeDistance;
		if (isLeftSwipe) {
			input.onSwipedLeft();
		}
		if (isRightSwipe) {
			input.onSwipedRight();
		}
	};

	return {
		onTouchStart,
		onTouchMove,
		onTouchEnd,
	};
};
