const API_URL_COM = "https://api.admin.kupastation.com";

const VERSION = "v1";
export default {
	STATS: `${API_URL_COM}/${VERSION}/stats/siteweb`,
	MACHINE_LOCATION: `${API_URL_COM}/${VERSION}/machine/location`,

	FACEBOOK: "https://www.facebook.com/kupastation",
	INSTAGRAM: "https://www.instagram.com/kupa_station",
	LINKEDIN: "https://www.linkedin.com/company/kupa-station-inc",
	YOUTUBE: "https://www.youtube.com/channel/UC83isDLN0F0lWv9ERJfv2Aw",
	TIKTOK: "https://www.tiktok.com/@kupastation",

	GOOGLE_PLAY:
		"https://play.google.com/store/apps/details?id=com.kupastation.appmachine",
	APPLE_STORE: "https://apps.apple.com/ca/app/kupa-station/id1544508406",

	CCLEVIS: "https://www.cclevis.ca",
	ECOCUP: "https://www.ecocup-quebec.ca",
	TOURISME_DURABLE: "https://tourismedurable.quebec",
	AQZD: "https://www.aqzd.ca",
	PAGES_VERTES: "https://lespagesvertes.ca",
	CCB: "https://www.ccb-e.ca",
	SADC: "https://www.sadc-cae.ca",
	FABRIQUE_QUEBEC:
		"https://lesproduitsduquebec.com/processus-de-verification",

	BROCHURE_FR: `${API_URL_COM}/${VERSION}/public/brochure_fr.pdf`,
	BROCHURE_EN: `${API_URL_COM}/${VERSION}/public/brochure_en.pdf`,

	// Translation
	TRANSLATION: `${API_URL_COM}/${VERSION}/translation/{{lng}}/SHOWCASE_WEB`,
};
