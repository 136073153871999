import { useHistory } from "react-router";

export default function useScrollTo(page, id) {
    const history = useHistory();

    return () => {
        if (page) history.push(page);

        if (id) {
            setTimeout(() => document.getElementById(id).scrollIntoView(), page ? 1 : 0);
        }
    }
}

// setTimeout(() => document.getElementById(id).scrollIntoView
//     (
//         {
//             behavior: smooth === true ? "smooth" : "auto"
//         }
//     ), page ? 1 : 0);