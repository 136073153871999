import OutilGestion from "views/IndexSections/OutilGestion";
import ApplicationMobile from "../IndexSections/AppilcationMobile";
import MetaDecorator from "../IndexSections/MetoDecorator";
import Footer from "../global/Footer";

// <section className="section section-lg" />

function Services() {
	return (
		<>
			<span id="Services" />
			<MetaDecorator title={"Services"} description={"services"} />
			<ApplicationMobile />
			<OutilGestion />

			<Footer />
		</>
	);
}

export default Services;
