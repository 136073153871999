import useScrollTo from "hooks/useScrollTo";
import "../../assets/css/smartTools.css";
import SmartToolsImage from "../../assets/img/smartTools/smartTools.png";
import CustomButton from "./CustomButton";
const { useTranslation } = require("react-i18next");

function SmartTools() {
	const { t } = useTranslation();
	const scroll = useScrollTo("/services", "Services");

	return (
		<div className="smartTools-div">
			<div className="smartTools-card">
				<p className="smartTools-title">Kupa Station</p>
				<span>{t("home.smarttools")}</span>
				<span style={{ paddingTop: "10px", whiteSpace: "nowrap" }}>
					{t("home.smarttools.text")}
				</span>
				<CustomButton
					className="mt-4 kupagreen-hover"
					textColor="var(--kupawhite)"
					text={t("home.smarttools.explore")}
					click={() => scroll()}
				/>
			</div>
			<img
				className="smartTools-image"
				src={SmartToolsImage}
				alt="kupa station smartTools"
			/>
		</div>
	);
}
export default SmartTools;
