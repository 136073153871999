import { Spinner } from "reactstrap";

// https://getbootstrap.com/docs/4.3/components/spinners

function CustomSpinner({ size, color }) {
	return (
		<div className="d-flex justify-content-center">
			<Spinner
				style={{
					color: color ?? "var(--kupablue)",
					width: size + "rem",
					height: size + "rem",
				}}
				children={""}
			/>
		</div>
	);
}

export default CustomSpinner;
