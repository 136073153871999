import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { Col, Row } from "reactstrap";

import FacebookIcon from "../global/media/FacebookIcon";
import InstagramIcon from "../global/media/InstagramIcon";
import LinkedinIcon from "../global/media/LinkedinIcon";
import YouTubeIcon from "../global/media/YouTubeIcon";
import TokTikIcon from "./media/TokTikIcon";

import { useEffect, useState } from "react";
import Background from "views/IndexSections/Background";
import ocean from "../../assets/img/ocean.png";
import contact from "../../assets/json/contact.json";
import { onMobile } from "./App";

export default function Footer() {
	const { t } = useTranslation();

	const c = 0.8;

	function calc() {
		const width = window.innerWidth;
		if (width <= 576) return c * width;
	}

	const [size, setSize] = useState(calc());

	useEffect(() => {
		const handleSize = () => setSize(calc());

		window.addEventListener("resize", handleSize);

		return () => window.removeEventListener("resize", handleSize);
	}, []);

	return (
		<>
			<div id="Contact" className="">
				<Background
					image={ocean}
					color="var(--kupablue)"
					colorOpacity={0.8}
					padding={20}
					backgroundX={50}
				>
					<div
						className="text-center"
						style={{
							//backgroundColor: "var(--kupabluelight)",
							padding: 5,
							marginLeft: !onMobile() ? "120px" : "0px",
							marginRight: !onMobile() ? "100px" : "0px",
							color: "var(--kupawhite)",
							fontSize: 40,
						}}
					>
						<Row>
							<Col style={{ marginTop: !onMobile() ? 25 : 10 }}>
								<h2 className="contact ">
									{t("footer.contact")}
								</h2>
								<div className="text-left mt-2">
									<p className="textKupa text-white font-weight-bold ">
										{t("email")} :{" "}
										<a
											href={`mailto:${contact.email}`}
											style={{
												color: "var(--kupablue)",
											}}
											className="textKupa text-white "
										>
											{contact.email}
										</a>
									</p>
									<p
										style={{ color: "black" }}
										className="textKupa text-white"
									>
										{t("phone")} :{" "}
										<a
											href={`tel:${contact.tel}`}
											style={{
												color: "var(--kupablue)",
											}}
											className="textKupa text-white "
										>
											{contact.tel}
										</a>
									</p>
									<p
										style={{ color: "black" }}
										className="textKupa text-white"
									>
										{t("address")} :{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href={`https://maps.google.com/?q=${contact.address}`}
											style={{
												color: "var(--kupablue)",
											}}
											className="textKupa text-white "
										>
											{contact.address_line1}
											<br />
											{contact.address_line2}
										</a>
									</p>
								</div>
							</Col>

							<Col style={{ marginTop: !onMobile() ? 25 : 15 }}>
								<div className="cyber_Impact text-left">
									<h2 className="contact_newsletter ">
										{t("footer.newsletter")}
									</h2>
									<iframe
										id="cyberImpact"
										title="cyberImpact Abonnement"
										style={{
											width: size,
										}}
										src={`https://app.cyberimpact.com/clients/40495/subscribe-forms/${
											i18n.language === "en"
												? "F30E7659-B9A7-4412-8C02-E8BBABE1DD5F"
												: "65D2A211-CB60-47A8-8D35-93791BF70BD6"
										}`}
										width={"350px"}
										height={onMobile() ? "130px" : "160px"}
										frameBorder="0"
										marginHeight="0"
										marginWidth="0"
										scrolling="auto"
										className="cyberImpactFrame"
									/>
								</div>
							</Col>
						</Row>
					</div>
				</Background>
				<div
					style={{
						backgroundColor: "var(--kupablack)",
						padding: 10,
						fontSize: 15,
					}}
				>
					<div className="gridColumns">
						<div>
							<span
								className="textKupa"
								style={{
									color: "var(--kupawhite)",
									fontSize: 15,
								}}
							>
								@ {new Date().getFullYear()} KUPA Station.{" "}
								{t("copyright")}
							</span>
						</div>

						<div>
							<span
								style={{
									position: "absolute",
									right: "30px",
								}}
							>
								<FacebookIcon
									color="var(--kupawhite)"
									size={20}
									space={20}
								/>
								<InstagramIcon
									color="var(--kupawhite)"
									size={20}
									space={20}
								/>
								<TokTikIcon
									color="var(--kupawhite)"
									size={18}
									space={20}
								/>
								<YouTubeIcon
									color="var(--kupawhite)"
									size={20}
									space={20}
								/>
								<LinkedinIcon
									color="var(--kupawhite)"
									size={20}
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
