import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Background from "views/IndexSections/Background";
import Carroussel from "views/IndexSections/Carroussel/Carroussel";
import oceon from "../../../assets/img/ocean.png";
import ProductsCard from "./ProductsCard";
function Products() {
	const { t } = useTranslation();

	function productImg(name) {
		return `https://api.admin.kupastation.com/public/${name}.png`;
	}
	const type = [
		"all",
		"flavors",
		"mixes",
		"mocktails",
		/* "cocktails", */ "options",
	];
	const products = [
		{ name: "frambo", type: "flavors" },
		{ name: "hibiscus", type: "flavors" },
		{ name: "citron", type: "flavors" },
		{ name: "melon", type: "flavors" },
		{ name: "pample", type: "flavors" },
		{ name: "menthe", type: "flavors" },
		{ name: "cerise", type: "flavors" },
		{ name: "fraise", type: "flavors" },
		{ name: "lime", type: "flavors" },
		{ name: "mango", type: "flavors" },
		{ name: "passionfruit", type: "flavors" },
		{ name: "mandarin", type: "flavors" },
		{ name: "fracit", type: "mixes" },
		{ name: "mencit", type: "mixes" },
		{ name: "framel", type: "mixes" },
		{ name: "noixana", type: "mixes" },
		{ name: "frahibis", type: "mixes" },
		{ name: "tomcollins", type: "mocktails" },
		{ name: "colada", type: "mocktails" },
		{ name: "mojito", type: "mocktails" },
		{ name: "paloma", type: "mocktails" },
		{ name: "elect", type: "options" },
		{ name: "touche", type: "options" },
		{ name: "sparkling", type: "options" },
	];
	const [selectedType, setSelectedType] = useState("all");
	const [page, setPage] = useState(0);

	const handleTypeChange = (type) => {
		setSelectedType(type);
		setPage(0);
	};

	const filteredProducts =
		selectedType === "all"
			? products
			: products.filter((product) => product.type === selectedType);
	const [productsPerPage, setproductsPerPage] = useState(3);

	const [height, setheight] = useState(50);
	const [divHeight, setDivHeight] = useState(30);

	const [width, setwidth] = useState("50px");

	const adjustLayout = () => {
		if (window.innerWidth < 550) {
			setproductsPerPage(3);
			setheight(80);
			setDivHeight(30);
			setwidth("100px");
			setPage(0);
		} else if (window.innerWidth < 800) {
			setproductsPerPage(4);
			setheight(90);
			setDivHeight(35);
			setwidth("130px");
			setPage(0);
		} else if (window.innerWidth < 1000) {
			setheight(130);
			setDivHeight(40);
			setwidth("150px");
		} else if (window.innerWidth < 1100) {
			setproductsPerPage(5);
			setheight(150);
			setDivHeight(45);
			setwidth("180px");
			setPage(0);
		} else if (window.innerWidth > 1100) {
			setproductsPerPage(6);
			setDivHeight(50);
			setheight(150);
			setwidth("180px");
			setPage(0);
		}
	};
	useEffect(() => {
		adjustLayout();

		const handleResize = () => {
			adjustLayout();
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			<div className="mt-5">
				<div className="products_types">
					{type.map((type) => (
						<div
							className={`products_types_button ${
								type === selectedType
									? "products_types_button_selected"
									: undefined
							}`}
							key={type}
							onClick={() => handleTypeChange(type)}
						>
							{t(type)}
						</div>
					))}
				</div>

				<Carroussel
					list={filteredProducts.map((product) => (
						<ProductsCard
							key={product.name}
							title={t(product.name)}
							width={width}
							height={height}
							divHeight={divHeight}
							imgSrc={productImg(product.name)}
						/>
					))}
					productsPerPage={productsPerPage}
					page={page}
					setPage={setPage}
				/>
			</div>
			<Background
				image={oceon}
				color="var(--kupablue)"
				colorOpacity={0.8}
				padding={40}
				backgroundX={50}
				className="mb-5"
			></Background>
		</>
	);
}

export default Products;
