import URLS from "../js/Urls";

async function fetchData(URL) {
	const response = await fetch(URL, {
		method: "GET",
		headers: { "Content-Type": "application/json" },
	});
	return response.json();
}

export async function machineLocations() {
	return await fetchData(URLS.MACHINE_LOCATION);
}

export async function stats() {
	return await fetchData(URLS.STATS);
}
