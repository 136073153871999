import { Trans, useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { onMobile } from "views/global/App";
import about from "../../assets/img/About.png";

function AboutInformation() {
	const { t } = useTranslation();

	return (
		<div>
			{!onMobile() ? (
				<div>
					<div className=" gridColumns mt-6">
						<div className="m-3 paragraph">
							<Container className="px-5">
								<h1 className="text-center">{t("about.us")}</h1>
								<div
									style={{ textAlign: "justify" }}
									className="textKupa mt-3"
								>
									<Trans i18nKey="about.us.text"></Trans>
								</div>
							</Container>
						</div>
						<div className=" d-flex justify-content-center">
							<img
								className="bigImage"
								src={about}
								alt="Kupa Station Informations"
							/>
						</div>
					</div>
				</div>
			) : (
				<div>
					<Container className="mt-7  ">
						<div className="text-center px-4">
							<div className="text-center">
								<h1>{t("about.us")}</h1>
							</div>
							<div
								style={{ textAlign: "justify" }}
								className="textKupa  mt-2"
							>
								<Trans i18nKey="about.us.text"></Trans>
							</div>
						</div>
					</Container>
					<img
						className="bigImage mt-3 "
						src={about}
						alt="Kupa Station Informations"
					/>
				</div>
			)}
		</div>
	);
}

export default AboutInformation;
