import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { onMobile } from "views/global/App";
import "../../assets/css/medias.css";

function Medias() {
	const { t } = useTranslation();
	useEffect(() => {
		if (window.FB) {
			window.FB.XFBML.parse();
		}
	}, []);
	const mediaItems = [
		{
			name: "Donner le goût de l'eau | LaPresse+",
			description:
				"Boire de l’eau dans une bouteille réutilisable pour éviter les bouteilles jetables, c’est une bonne idée. Mais que faire si on n’a pas envie de boire de l’eau… plate ? La Ville de Québec teste une",
			link: "https://plus.lapresse.ca/screens/3a3f1352-1e55-499f-9a44-afdeb0504d12__7C___0.html",
			image: "http://plus.lapresse.ca/screens/captures/3a3f1352/1e55/499f/9a44/afdeb0504d12_0.jpg",
			alt: "Fontaine d'eau",
		},
		{
			name: "Éliminer les bouteilles à usage unique | Radio Canada",
			description:
				"Boire de l’eau dans une bouteille réutilisable pour éviter les bouteilles jetables, c’est une bonne idée. Mais que faire si on n’a pas envie de boire de l’eau… plate ? La Ville de Québec teste une",

			link: "https://ici.radio-canada.ca/tele/le-telejournal-quebec/site/segments/reportage/354980/marie-audrey-houle-station-deau-recyc-quebec-kupa",
			image: "https://images.radio-canada.ca/w_635,h_357/v1/ici-info/16x9/station-kupa.jpg",
			alt: "Fontaine d'eau",
		},
	];

	return (
		<Container className="mt-5">
			<div className="text-center">
				<h1 className="mb-2">{t("about.medias")}</h1>

				<iframe
					title="Prévost et Kupa en symbiose"
					src="https://www.facebook.com/plugins/video.php?height=295&href=https%3A%2F%2Fweb.facebook.com%2FSynergiebe%2Fvideos%2F1386423438673546%2F&show_text=false&width=560&t=0"
					width={onMobile() ? "250" : "550"}
					height={onMobile() ? "" : "300"}
					style={{ border: "none", overflow: "hidden" }}
					scrolling="no"
					frameborder="0"
					allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
				></iframe>
			</div>
			<ul className="p-0">
				<div
					className={`mt-2 ${
						onMobile() ? "medias_mobile" : "about.medias"
					} justify-content-center`}
				>
					{mediaItems.map((el, index) => (
						<li className="element" key={index}>
							<div className="testing d-flex justify-content-center">
								<a
									href={el.link}
									//className="font-weight-bold "
									target="_blank"
									className="elementA d-flex flex-column align-items-center"
									rel="noreferrer"
								>
									{" "}
									<img
										width={onMobile() ? "200" : "400"}
										height={onMobile() ? "150" : "250"}
										src={el.image}
										alt={el.alt}
										style={{
											opacity: 0.7,
										}}
										className="imageTest"
									/>{" "}
									<div className="content overlayImage" />
									<div className="textWhite text-center m-0">
										{el.name}
									</div>
								</a>
							</div>{" "}
						</li>
					))}
				</div>{" "}
			</ul>
		</Container>
	);
}

export default Medias;
