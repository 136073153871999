function PopoverMachine({ machine }) {
	return (
		<div className="font-weight-bold">
			<div>
				<span
					style={{
						color: machine.public
							? "var(--kupablue)"
							: "var(--kupagreen)",
					}}
				>
					{machine.public_name}
				</span>
				<span className="mx-2">
					({machine.count}{" "}
					{machine.count > 1 ? "stations" : "station"})
				</span>
			</div>

			<div className="address-line full-width">{machine.address}</div>

			<a
				target="_blank"
				rel="noreferrer"
				href={`https://maps.google.com/?q=${machine.address}`}
			>
				Google Maps
			</a>
		</div>
	);
}

export default PopoverMachine;
