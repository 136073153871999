import Urls from "js/Urls";
import React, { useEffect } from "react";
import ApplicationMobile from "views/IndexSections/AppilcationMobile";

const OpenAppButton = () => {
	const openApp = () => {
		const userAgent =
			navigator.userAgent || navigator.vendor || window.opera;

		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			window.location.href = Urls.APPLE_STORE;
		}
	};

	useEffect(() => {
		openApp();
	}, []);
	return <ApplicationMobile />;
};

export default OpenAppButton;
