import { stats } from "api/requests.js";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { machineLocations } from "../../api/requests.js";
import CustomSpinner from "./CustomSpinner";

function Counter() {
	const { t } = useTranslation();

	const [totalOrders, setTotalOrders] = useState(undefined);
	const [numMachine, setNumMachine] = useState(undefined);
	const [downloadedApps, setDownloadedApps] = useState(undefined);
	const [fetchError, setFetchError] = useState(false);

	const [status, setStatus] = useState(false);

	useEffect(() => {
		async function fetchData() {
			machineLocations().then((response) => {
				setNumMachine(response.length);
			});
			stats()
				.then((response) => {
					setTotalOrders(response.orderCount);
					setDownloadedApps(response.userCount);
				})
				.catch((error) => setFetchError(true));
		}
		fetchData();
	}, []);

	function formattedNumber(num) {
		return num.toLocaleString("en-US");
	}

	const onEndStatus = () => setStatus(true);

	return (
		<Container className="mt-2">
			<span id="Counter" />
			{
				/* isAtVar */ true &&
					!fetchError &&
					(totalOrders === undefined ||
					numMachine === undefined ||
					downloadedApps === undefined ? (
						<CustomSpinner size={6} color="white" />
					) : (
						<div className="counter-div">
							<div md="4" className="counter">
								<div className="centerCounter">
									<div className="textCounter">
										{t("counters.stations")}
									</div>
									<h4 className="numberCounter">
										{status ? (
											formattedNumber(numMachine)
										) : (
											<CountUp
												className="numberCounter"
												delay={0.5}
												end={numMachine}
												duration={1.5}
											/>
										)}
									</h4>
								</div>
							</div>

							<div md="4" className="counter">
								<div className="centerCounter">
									<div className="textCounterBig">
										{t("counters.global")}
									</div>
									<h4 className="  numberCounterBig">
										{status ? (
											formattedNumber(totalOrders)
										) : (
											<CountUp
												className="numberCounterBig"
												delay={0.2}
												end={totalOrders}
												onEnd={onEndStatus}
												duration={1.5}
											/>
										)}
									</h4>
								</div>
							</div>

							<div md="4" className="counter">
								<div className="centerCounter">
									<div className="textCounter">
										{t("counters.downloads")}
									</div>
									<h4 className=" numberCounter">
										{status ? (
											formattedNumber(downloadedApps)
										) : (
											<CountUp
												className="numberCounter"
												delay={0.5}
												end={downloadedApps}
												duration={1.5}
											/>
										)}
									</h4>
								</div>
							</div>
						</div>
					))
			}
		</Container>
	);
}

export default Counter;
