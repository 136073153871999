function CustomButton({ className, textColor, text, click, disabled = false }) {
	return (
		<button
			style={{
				paddingTop: 5,
				paddingBottom: 5,
				paddingLeft: 25,
				paddingRight: 25,
				borderRadius: 50,
				fontSize: 18,
				color: textColor,
			}}
			onClick={click}
			className={`btn ${className} textKupa`}
			disabled={disabled}
		>
			{text}
		</button>
	);
}

export default CustomButton;
